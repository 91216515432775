<template>
    <div class="s-card-content s-bg-white">
        <div class="s-flex s-p-16">
            <div class="s-fullWidth s-position-relative">
                <div @click.prevent="menu = !menu">
                    <Input v-model="category1" label="Topics" :rightIcon="true" :readonly="true" :clickable="true" placeholder="Click here..." >
                    <template #rightIcon>
                        <div class="s-square-16">
                            <img class="s-fullWidth s-cursor-pointer" :src="require('@/assets/icons/primary/icon-arrow-down.svg')"/>
                        </div>
                    </template>
                    </Input>
                </div>
                <div v-if="menu" @mouseleave="menu = false" class="s-position-absolute s-card s-fullWidth s-p-5 s-bg-white s-dropdown" style=" z-index: 100">
                    <div>
                        <Input v-model="autocomplete">
                        </Input>
                    </div>
					<div class="s-card-item s-cursor-pointer s-plr-10" v-for="(i, index) in expertiseList" :key="`expertise-${index}`" @click.prevent="category1 = i.name, menu = false, id = i.id">
                        <p>{{i.name}}</p>
                    </div>
				</div>
            </div>
        </div>
        <div class="s-flex s-p-16">
            <Button class="s-ml-auto" @click.prevent="pushRoute()">Apply</Button>
        </div>
    </div>
</template>
<script>
import form from '@/mixins/form'
import { mapActions, mapGetters } from 'vuex'
export default {   
    mixins: [form],
    data: () => ({
        category1: '',
        menu: false,
        id: '',
        autocomplete: ''
    }),
    computed: {
        ...mapGetters({
            expertise: 'expertise/getExpertise'
        }),
        expertiseList(){
            if(!this.autocomplete) return this.expertise
            return this.expertise.filter(row => {
                return row.name.toLowerCase().includes(this.autocomplete.toLowerCase())
            })
        }
    },
    mounted(){
        this.initData()
    },
    methods: {
        ...mapActions({
            setExpertise: 'expertise/setExpertisePublicData'
        }),
        async initData(){
            await this.setExpertise()
        },
        pushRoute(){
            const query = {
                ...this.$route.query,
                filter: this.id
            }
            this.$router.push(this.$translate({name: 'Content List', query: query}))
                        .catch(error => {
                            if (error.name != "NavigationDuplicated") throw error;
                        })
        }
    }
    
}
</script>